<!--
 * @Author: your name
 * @Date: 2020-08-27 12:01:36
 * @LastEditTime: 2020-09-03 15:00:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\EBCHome\statisticalAnalysis\detailPop.vue
-->
<template>
    <div class="sourceAnalysis-detailPop">
        <h4>
            <p>详情</p>
            <div class="light-beam"></div>
        </h4>
        <div class="row-con">
            <div class="row-item">
                <div class="flex-item">
                    <span class="iconfont iconlocation"></span>
                    <span>货源地名称：{{ sourceDetail.name }}</span>
                </div>
                <div class="flex-item">
                    <span class="iconfont iconwenjianxinxi"></span>
                    <span>货源地编号：{{ sourceDetail.no }}</span>
                </div>
            </div>
            <div class="row-item">
                <div class="flex-item">
                    <span class="iconfont iconlocation"></span>
                    <span>货源地简称：{{ sourceDetail.short_name }}</span>
                </div>
                <div class="flex-item">
                    <span class="iconfont iconqiye"></span>
                    <span>单位地址：{{ sourceDetail.address }}</span>
                </div>
            </div>
            <div class="row-item">
                <div class="flex-item">
                    <span class="iconfont iconmin7"></span>
                    <span>联系人：{{ sourceDetail.leader }}</span>
                </div>
                <div class="flex-item">
                    <span class="iconfont iconmin7"></span>
                    <span>联系电话：{{ sourceDetail.telephone }}</span>
                </div>
            </div>
            <div class="row-item">
                <span class="iconfont iconfenzu"></span>
                <span>原材料分类：{{ sourceDetail.material_names }}</span>
            </div>
            <div class="row-item">
                <span class="iconfont iconqiye"></span>
                <span>管理单位：{{ sourceDetail.org_name }}</span>
            </div>
            <div class="row-item">
                <span class="iconfont iconico"></span>
                <span>统一信用代码：{{ sourceDetail.credit_code }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            sourceDetail: {},
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.sourceDetail = this.extr.detailData;
    },
    methods: {},
};
</script>
<style lang="stylus">
.sourceAnalysis-detailPop
    height 100%
    background #032041
    box-shadow 1px 1px 6px 0px rgba(0,0,0,0.8)
    padding .2rem
    font-size .16rem
    h4
        font-size .18rem
        color #fff
        line-height .27rem
        background linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(108,151,216,1) 100%)
        -webkit-background-clip text
        -webkit-text-fill-color transparent
        text-align center
        position relative
        .light-beam
            position absolute
            top .1rem
            width 100%
            background url(./../images/line.png) no-repeat
            height .47rem
            background-size 100% 100%
    .row-con
        margin-top .4rem
        .row-item
            color #FFFFFF
            display flex
            margin-bottom .2rem
            line-height .2rem
            .flex-item
                flex 1
            .iconfont
                vertical-align middle
                margin-right .05rem
</style>